<template>
<div id="trendList">
  <div class="trendWrap">
    <div class="trend-header">
      <span>公司动态</span>
    </div>
    <div class="trend-list">
      <div class="trend-item" v-for="(item,index) in trendList" :key="index" @click="jumpTrend(item)">
        <img :src="item.homeImage" />
        <h3>
          <span>{{item.title}}</span>
          <p v-html="item.content"></p>
          <label>{{item.createTime}}</label></h3>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
import { getCompanyDynamicsList } from "@/api/index.js";
export default {
  name: "trendList",
  data() {
    return {
      trendList: [],
      pageSize:10,
      currentPage:1,
      total:0
    }
  },
  mounted() {
    this.getCompanyDynamicsList()
  },
  methods: {
    jumpTrend(item){
      this.$router.push({
        path:'/trendDetail',
        query:{
          id:item.id
        }
      })
    },
    getCompanyDynamicsList() {
      getCompanyDynamicsList({
        type: this.type,
        page:this.currentPage,
        size: this.pageSize
      }).then(res => {
        this.trendList = res.rows
        this.total  = res.total
      })
    },
    sizeChange(val){
      this.pageSize = val
      this.getCompanyDynamicsList()
    },
    currentChange(val){
      this.currentPage = val
      this.getCompanyDynamicsList()
    }
  }
};
</script>

<style lang="scss" scoped>
#trendList {
  width: 100%;
  min-height: 600px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 24px;
}

.trendWrap {
  width: 1000px;
  min-height: 500px;
  margin: 0 auto 30px;
  padding-left: 20px;
  padding-right: 20px;
  background: #FFFFFF;
   overflow: hidden;
}

.trend-header {
  border-bottom: 1px solid #eee;
  height: 74px;
  line-height: 74px;

  span {
    height: 74px;
    width: 100px;
    text-align: center;
    line-height: 74px;
    display: block;
    border-bottom: 3px solid #0077FF;
    color: #2C87FF;
    font-size: 20px;

  }
}

.trend-item {
  border-bottom: 1px solid #eee;
  padding:30px 20px;
  cursor: pointer;
  clear: both;
  overflow: hidden;
  img{
    width: 181px;
    height: 131px;
    float: left;
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #222222;
    float: left;
    margin-left: 21px;

    p{
      width:750px;
      height: 53px;
      overflow: hidden;
      margin-top: 19px;
      font-size: 16px;
color: #666666;
line-height: 25px;
    }
    label{
      display: block;
      font-size: 16px;
color: #999999;
margin-top: 17px;
    }
  }

}
.pagination{
  margin: 20px auto;
}
.trend-item:hover {
  h3 {
    color: #0077FF;
  }
}
</style>
